const preset = {
  theme: {
    extend: {
      gridTemplateColumns: {
        '14': 'repeat(14, minmax(0, 1fr))',
        '16': 'repeat(16, minmax(0, 1fr))'
      },
      screens: {
        'sm': '472px',
        'md': '688px',
        'lg': '1012px',
        'xl': '1336px',
        '2xl': '1336px'
      },
      aspectRatio: {
        'banner-desktop': '1336/300',
        'banner-mobile': '390/240'
      },
      colors: {
        'plum': '#473a4e',
        'plum-400':'#5F4D69',
        'plum-dark':'#312836',
        'dark-gray': '#374151',
        'cool-gray': '#F3F4F6',
        'sunset-orange': '#FF543D',
        'azure': '#0A66A8',
        'azure-dark':'#07395E',
        'claret': '#5D0201',
        'coolGray': '#9ca3af',
        'celadon':'#13A897',
        'grass-green':'#378F10',
        'dark-red': '#A80D0A',
        'error-red':'#E6202D',
        'karmin':'#82201E',
        'sad-pink': '#A80041'
      },
      margin: {
        '30': '7.5rem'
      },
      fontSize: {
        '10xl': ['9rem', {
          'lineHeight': 1
        }],
        'tiny': '11px',
        'micro': '9px',
      },
      zIndex: {
        '60': '60',
        '70': '70'
      },
      boxShadow: {
        'box-top':     '0px -6px 6px -6px rgba(0,0,0,0.10), 0px -4px 4px -4px rgba(0,0,0,0.06), 0px -5px 5px -5px rgba(0,0,0,0.15)',
        'box':     '0px 4px 6px 0px rgba(0,0,0,0.10), 0px 2px 4px 0px rgba(0,0,0,0.06), 0px 1px 5px 0px rgba(0,0,0,0.15)',
        'blog-box-card': '0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.06), 0px 4px 6px 0px rgba(0, 0, 0, 0.10);',
        'box-white':'0px 4px 6px 0px rgba(255,255,255,1)',
        'box-hover': '0px 10px 10px 0px rgba(55,65,81,0.50), 0px 20px 25px 0px rgba(0,0,0,0.10), 0px 0px 4px 0px rgba(0,0,0,0.20)',
        'brand-hover':'0px 4px 6px 0px rgba(0,0,0,0.05), 0px 10px 15px 0px rgba(0,0,0,0.10), 0px 1px 5px 0px rgba(0,0,0,0.15)'
      },
      width: {
        '15': '3.75rem',

      },
      height: {
        '15': '3.75rem'
      },
      transitionProperty: {
        'position': 'left, top, right, bottom',
        'mt':'margin-top 0.2s ease-in-out'
      },
      backgroundImage: {
        'light-gray-gradient' : 'linear-gradient(180deg,#FFF 0%,#F3F4F6 30.07%,#F3F4F6 70.24%,#FFF 100%)',
        'unset': 'unset',
        'multi-gray-gradient': 'linear-gradient(0deg, #F3F4F6 0%, #E5E7EB 17.66%, #9CA3AF 35.12%, #9CA3AF00 35.13%), linear-gradient(180deg, #E5E7EB 0%, #FFF 70.19%)'
      }
    }
  }
}

if(typeof module !== "undefined") {
  module.exports = preset;
}
export default preset;
